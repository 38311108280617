function showNoAccessForCompanyPageModal() {
  EventBus.$emit('showNoAccessForCompanyPageModal')
}

export default function noCompanyPage({ next, store }) {
  if (store.getters['auth/user'].active_business_profile && 'id' in store.getters['auth/user'].active_business_profile) {
    // if page loaded first time
    if (document.readyState === 'loading') {
      window.addEventListener('load', () => {
        showNoAccessForCompanyPageModal()
      })
    }
    showNoAccessForCompanyPageModal()
    return next({ name: 'home' })
  }

  return next()
}
