import axiosIns from '../../libs/axios'

export default {
  namespaced: true,
  state: {
    jobCategories: [],
  },
  getters: {
    jobCategories: state => state.jobCategories,
  },
  mutations: {
    setJobCategories(state, jobCategories) {
      state.jobCategories = jobCategories
    },
  },
  actions: {
    async fetchJobCategories({ dispatch, commit }) {
      try {
        const { data: { data: jobCategories } } = await axiosIns.get('/job-categories')
        commit('setJobCategories', jobCategories)
      } catch (e) {
        dispatch('errorHandler', e, { root: true })
        throw e
      }
    },
  },
}
