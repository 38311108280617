import Vue from 'vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import i18n from '@/libs/i18n/index'

export default function guest({ next, store }) {
  if (store.getters['auth/isAuthenticated']) {
    Vue.$toast({
      component: ToastificationContent,
      props: {
        title: i18n.t('This page is for unauthorized users only!'),
        icon: 'AlertCircleIcon',
        variant: 'warning',
      },
    })

    return next({ name: 'home' })
  }
  return next()
}
