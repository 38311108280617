/* eslint-disable import/no-cycle */
import axiosIns from '../../libs/axios'

export default {
  namespaced: true,
  state: {
    post: null,
  },
  getters: {
    post: state => state.post,
  },
  mutations: {
    setPost(state, post) {
      state.post = post
    },
  },
  actions: {
    async fetchPostById({ dispatch, commit }, id) {
      try {
        const { data } = await axiosIns.get(`/posts/${id}`)
        if (data) {
          commit('setPost', data)
        }
        return data
      } catch (e) {
        dispatch('errorHandler', e, { root: true })
        throw e
      }
    },
    async deletePostById({ dispatch }, payload) {
      try {
        const response = await axiosIns.delete(`/${payload.owner === 'user' ? 'user-posts' : 'partner-company-posts'}/${payload.slug}`)
        return response
      } catch (e) {
        dispatch('errorHandler', e, { root: true })
        throw e
      }
    },
    async archivePostById({ dispatch }, payload) {
      try {
        const response = await axiosIns.put(`/${payload.owner === 'user' ? 'user-posts' : 'partner-company-posts'}/${payload.slug}/archive`, {
          archive: 'archive',
        })
        return response
      } catch (e) {
        dispatch('errorHandler', e, { root: true })
        throw e
      }
    },
    async unarchivePostById({ dispatch }, payload) {
      try {
        const response = await axiosIns.put(`/${payload.owner === 'user' ? 'user-posts' : 'partner-company-posts'}/${payload.slug}/archive`, {
          archive: 'unarchive',
        })
        return response
      } catch (e) {
        dispatch('errorHandler', e, { root: true })
        throw e
      }
    },
    async clearPost({ dispatch, commit }) {
      try {
        commit('setPost', null)
      } catch (e) {
        dispatch('errorHandler', e, { root: true })
        throw e
      }
    },
  },
}
