/* eslint-disable import/no-cycle */
import axiosIns from '../../libs/axios'

export default {
  namespaced: true,
  state: {
    searchFollowers: ' ',
    searchUsers: '',
    followers: [],
    users: [],
  },
  getters: {
    searchFollowers: state => state.searchFollowers,
    searchUsers: state => state.searchUsers,
    followers: state => state.followers,
    users: state => state.users,
  },
  mutations: {
    setFollowers(state, followers) {
      state.followers = followers
    },
    setSearchFollowers(state, searchFollowers) {
      state.searchFollowers = searchFollowers
    },
    setUsers(state, users) {
      state.users = users
    },
    setSearchUsers(state, searchUsers) {
      state.searchUsers = searchUsers
    },
  },
  actions: {
    async fetchFollowers({ dispatch, commit, state }, slug) {
      try {
        const { data: res } = await axiosIns.get(`/profile/${slug}/followers?name=${state.searchFollowers}`)
        if (res.data) {
          commit('setFollowers', res.data)
        }
      } catch (e) {
        dispatch('errorHandler', e, { root: true })
        throw e
      }
    },
    async searchUsers({ dispatch, commit, state }) {
      try {
        const res = await axiosIns.get(`profile/search?name=${state.searchUsers}&type=user`)

        if (res.status === 200) {
          commit('setUsers', res.data.data)
        }
      } catch (e) {
        dispatch('errorHandler', e, { root: true })
        throw e
      }
    },
  },
}
