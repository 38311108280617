import axiosIns from '../../libs/axios'

export default {
  namespaced: true,
  state: {
    categories: [],
    pages: [],
  },
  getters: {
    categories: state => state.categories,
    pages: state => state.pages,
  },
  mutations: {
    setCategories(state, categories) {
      state.categories = categories
    },
    setPages(state, pages) {
      state.pages = pages
    },
  },
  actions: {
    async fetchCategories({ dispatch, commit }) {
      try {
        const { data: { data: categories } } = await axiosIns.get('/categories?type=company')
        commit('setCategories', categories)
        const pages = categories.map(el => ({
          title: el.name,
          route: {
            path: `/pages/${el.slug}`,
          },
          is_child: true,
        }))
        commit('setPages', pages)
      } catch (e) {
        dispatch('errorHandler', e, { root: true })
        throw e
      }
    },
  },
}
