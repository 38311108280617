import axiosIns from '../../libs/axios'

export default {
  namespaced: true,
  state: {
    videos: [],
    pageNumber: 1,
    selectedVideo: null,
    categories: [],
    selectedCategories: [],
    search: null,
    direction: {
      name: 'Recent',
      value: 'asc',
    },
  },
  getters: {
    videos: state => state.videos,
    selectedVideo: state => state.selectedVideo,
    categories: state => state.categories,
    selectedCategories: state => state.selectedCategories,
    search: state => state.search,
    direction: state => state.direction,
    pageNumber: state => state.pageNumber,
    query(state) {
      return {
        pageNumber: state.pageNumber,
        selectedCategories: state.selectedCategories,
        search: state.search,
      }
    },
  },
  mutations: {
    setVideos(state, videos) {
      state.videos = videos
    },
    setDirection(state, direction) {
      state.direction = direction
    },
    setSelectedVideo(state, selectedVideo) {
      state.selectedVideo = selectedVideo
    },
    setCategories(state, categories) {
      state.categories = categories
    },
    setSelectedCategories(state, selectedCategories) {
      state.selectedCategories = selectedCategories
    },
    setSearch(state, search) {
      state.search = search
    },
    setPageNumber(state, pageNumber) {
      state.pageNumber = pageNumber
    },
    setQuery(state, query) {
      state.pageNumber = query.pageNumber
      state.selectedCategories = query.selectedCategories
      state.search = query.search
      state.direction = query.direction
    },
    clearFilters(state) {
      state.pageNumber = 1
      state.selectedCategories = []
      state.search = null
    },
  },
  actions: {
    async fetchVideos({
      dispatch, commit, state, getters,
    }) {
      commit('setVideos', [])
      try {
        const { data: videos } = await axiosIns.get(`/videos?page%5Bnumber%5D=${state.pageNumber}`, {
          params: getters.query,
        })
        commit('setVideos', videos)
      } catch (e) {
        dispatch('errorHandler', e, { root: true })
        throw e
      }
    },
    async fetchVideosByCategory({ dispatch, commit, state }) {
      try {
        const query = {
          direction: state.direction.value,
        }
        const { data: videos } = await axiosIns.get(`/videos/category/${state.selectedCategory.id}?page%5Bnumber%5D=${state.pageNumber}`, {
          params: query,
        })
        commit('setVideos', videos)
      } catch (e) {
        dispatch('errorHandler', e, { root: true })
        throw e
      }
    },
    async fetchVideoById({ dispatch, commit }, id) {
      try {
        const { data: video } = await axiosIns.get(`/videos/${id}`)
        commit('setSelectedVideo', video)
      } catch (e) {
        dispatch('errorHandler', e, { root: true })
        throw e
      }
    },
    async fetchCategories({ dispatch, commit }) {
      try {
        const { data: { data: categories } } = await axiosIns.get('/video-categories')
        commit('setCategories', categories)
      } catch (e) {
        dispatch('errorHandler', e, { root: true })
        throw e
      }
    },
  },
}
