/* eslint-disable import/no-cycle */
import axiosIns from '../../libs/axios'
import sendAnalytic from '../../libs/sendAnalytic'

export default {
  namespaced: true,
  state: {
    course: [],
    pagination: null,
    archivedCourse: [],
    archivedPagination: null,
    selectedCourse: null,
    myCourse: null,
    search: null,
    filterBySpeciality: [],
    filterByCounty: [],
    pageNumber: 1,
    activeTab: 0,
    showOldCourse: false,
    courseAnalyticId: null,
  },
  getters: {
    course: state => (!state.showOldCourse ? [...state.course, ...state.archivedCourse] : state.course),
    actualCoursesCount: state => state.course.length,
    archivedCourse: state => state.archivedCourse,
    myCourse: state => state.myCourse,
    selectedCourse: state => state.selectedCourse,
    search: state => state.search,
    filterByCounty: state => state.filterByCounty,
    filterBySpeciality: state => state.filterBySpeciality,
    pageNumber: state => state.pageNumber,
    activeTab: state => state.activeTab,
    courseAnalyticId: state => state.courseAnalyticId,
    query(state) {
      return {
        search: state.search,
        filterBySpeciality: state.filterBySpeciality,
        filterByCounty: state.filterByCounty,
        pageNumber: state.pageNumber,
        activeTab: state.activeTab,
        showOldCourse: state.showOldCourse,
      }
    },
    showOldCourse: state => state.showOldCourse,
    pagination: state => {
      if (!state.showOldCourse && state.archivedPagination) {
        if (state.archivedPagination.total_pages === 1 && state.pagination.total_pages === 1) {
          return {
            total: 1,
            per_page: state.pagination.per_page,
          }
        }
        if (state.pagination.total === 0) {
          return {
            total: state.archivedPagination.total,
            per_page: state.pagination.per_page,
          }
        }
        return {
          total: state.archivedPagination.total + state.pagination.total - ((state.archivedPagination.total + state.pagination.total) % 10),
          per_page: state.pagination.per_page,
        }
      }
      return state.pagination
    },
  },
  mutations: {
    setCourse(state, course) {
      state.course = course
    },
    setCourseAnalyticId(state, id) {
      state.courseAnalyticId = id
    },
    setPagination(state, pagination) {
      state.pagination = pagination
    },
    setArchivedCourse(state, archivedCourse) {
      state.archivedCourse = archivedCourse
    },
    setArchivedPagination(state, archivedPagination) {
      state.archivedPagination = archivedPagination
    },
    setMyCourse(state, myCourse) {
      state.myCourse = myCourse
    },
    setSelectedCourse(state, selectedCourse) {
      state.selectedCourse = selectedCourse
    },
    setSearch: (state, search) => {
      state.search = search
    },
    setActiveTab: (state, activeTab) => {
      state.activeTab = activeTab
    },
    setFilterByCounty: (state, filterByCounty) => {
      state.filterByCounty = filterByCounty
    },
    setFilterBySpeciality: (state, filterBySpeciality) => {
      state.filterBySpeciality = filterBySpeciality
    },
    setPageNumber: (state, pageNumber) => {
      state.pageNumber = pageNumber
    },
    setQuery(state, query) {
      state.pageNumber = query.pageNumber
      state.filterByCounty = query.filterByCounty
      state.filterBySpeciality = query.filterBySpeciality
      state.search = query.search
      state.activeTab = query.activeTab
      state.showOldCourse = query.showOldCourse
    },
    clearFilters(state) {
      state.search = null
      state.filterByCounty = []
      state.filterBySpeciality = []
      state.pageNumber = 1
      state.activeTab = 0
      state.showOldCourse = false
    },
    setShowOldCourse: (state, value) => {
      if (value && state.pagination) {
        state.pageNumber = state.pagination.total_pages
      }
      state.showOldCourse = value
    },
  },
  actions: {
    async fetchCourses({ dispatch, commit, state }) {
      const query = {
        search: state.search,
        filterBySpeciality: state.filterBySpeciality,
        filterByCounty: state.filterByCounty,
      }
      try {
        commit('setCourse', [])
        const { data: course } = await axiosIns.get(`/courses?page%5Bnumber%5D=${state.pageNumber}`, {
          params: query,
        })
        commit('setCourse', course.data)
        commit('setPagination', course.meta.pagination)
        dispatch('fetchArchivedCourses')
      } catch (e) {
        dispatch('errorHandler', e, { root: true })
        throw e
      }
    },
    async fetchArchivedCourses({ dispatch, commit, state }) {
      const query = {
        search: state.search,
        filterBySpeciality: state.filterBySpeciality,
        filterByCounty: state.filterByCounty,
      }
      try {
        const { data: course } = await axiosIns.get(`/courses/archived?page%5Bnumber%5D=${state.pageNumber - state.pagination.total_pages + 1}`, {
          params: query,
        })
        if (!state.showOldCourse && (state.pageNumber - state.pagination.total_pages + 1) > 0) {
          commit('setArchivedCourse', course.data)
          commit('setArchivedPagination', course.meta.pagination)
        } else if (state.showOldCourse) {
          commit('setArchivedCourse', [])
          commit('setArchivedPagination', {
            total: 0,
            per_page: 0,
          })
        } else {
          commit('setArchivedCourse', [])
          commit('setArchivedPagination', course.meta.pagination)
        }
      } catch (e) {
        dispatch('errorHandler', e, { root: true })
        throw e
      }
    },
    async fetchCourseById({ dispatch, commit }, id) {
      try {
        const { data: course } = await axiosIns.get(`/courses/${id}`)
        commit('setSelectedCourse', course)
      } catch (e) {
        dispatch('errorHandler', e, { root: true })
        throw e
      }
    },
    async fetchMyCourses({ dispatch, commit, state }) {
      const query = {
        search: state.search,
        filterBySpeciality: state.filterBySpeciality,
        filterByCounty: state.filterByCounty,
      }
      try {
        const { data: course } = await axiosIns.get(`/courses/my?page%5Bnumber%5D=${state.pageNumber}`, {
          params: query,
        })
        commit('setMyCourse', course)
      } catch (e) {
        dispatch('errorHandler', e, { root: true })
        throw e
      }
    },
    async createCourse({ dispatch }, course) {
      try {
        return await axiosIns.post('/my/courses', course)
      } catch (e) {
        dispatch('errorHandler', e, { root: true })
        throw e
      }
    },
    async subscribeCourse({ dispatch }, id) {
      try {
        return await axiosIns.get(`/courses/${id}/subscribe`)
      } catch (e) {
        dispatch('errorHandler', e, { root: true })
        throw e
      }
    },
    async leaveCourse({ dispatch }, id) {
      try {
        return await axiosIns.get(`/courses/${id}/unsubscribe`)
      } catch (e) {
        dispatch('errorHandler', e, { root: true })
        throw e
      }
    },
    async userEnteredOnCourse({ commit }, courseId) {
      try {
        const { data: { id }, status } = await axiosIns.get(`/course-analytics/${courseId}/user-entered-on`)
        if (status === 201) {
          commit('setCourseAnalyticId', id)
        }
      } catch (e) {
        // continue regardless of error
      }
    },
    userLeftWatchingCourse({ state }) {
      if (state.courseAnalyticId) {
        sendAnalytic(`/course-analytics/${state.courseAnalyticId}/user-left-watching`)
      }
    },
  },
}
