function showOnReviewModal() {
  EventBus.$emit('showOnReviewModal')
}

export default function inReview({ next, store }) {
  if (store.getters['auth/user'].status_label === 'In review') {
    // if page loaded first time
    if (document.readyState === 'loading') {
      window.addEventListener('load', () => {
        showOnReviewModal()
      })
    }
    showOnReviewModal()
    return next({ name: 'home' })
  }
  return next()
}
