import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'
import vueDebounce from 'vue-debounce'
import attributesNo from 'vee-validate/dist/locale/nb_NO.json'
import attributesEn from 'vee-validate/dist/locale/en.json'
import * as VeeValidate from 'vee-validate'

// Mixins
import checkPermission from '@/helpers/check-permission'
import translate from '@/helpers/translate-filters'

import i18n from '@/libs/i18n'
import router from './router'
import store from './store'
import App from './App.vue'

// Global Components
import './global-components'

// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/sweet-alerts'
import '@/libs/toastification'

Vue.mixin({
  methods: {
    checkPermission,
    translate,
  },
})

// Translate validation messages
VeeValidate.localize(localStorage.getItem('locale') === 'en' ? 'en' : 'nb_NO', localStorage.getItem('locale') === 'en' ? attributesEn : attributesNo)
Vue.use(VeeValidate, {
  locale: localStorage.getItem('locale') === 'en' ? 'en' : 'nb_NO',
  errorBagName: 'validations',
  fieldsBagName: 'inputs',
  dictionary: {
    translationsEn: { attributes: attributesEn },
    translationsNo: { attributes: attributesNo },
  },
})

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

Vue.use(vueDebounce, {
  defaultTime: '1s',
})

// Composition API
Vue.use(VueCompositionAPI)

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

window.EventBus = new Vue()

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')
