import axiosIns from '../../libs/axios'

export default {
  namespaced: true,
  state: {
    departments: [],
  },
  getters: {
    departments: state => state.departments,
  },
  mutations: {
    setDepartments(state, departments) {
      state.departments = departments
    },
    clearDepartments(state) {
      state.departments = []
    },
  },
  actions: {
    async fetchDepartmentByHospitalClinicId({ dispatch, commit }, id) {
      try {
        const { data: departments } = await axiosIns.get(`/hospital-clinic-departments/${id}/hospital-clinic`)
        commit('setDepartments', departments)
      } catch (e) {
        dispatch('errorHandler', e, { root: true })
        throw e
      }
    },
  },
}
