<template>
  <div
    id="app"
    class="h-100"
    :class="[skinClasses]"
  >
    <Loader v-if="loading" />

    <component :is="layout">
      <router-view />
    </component>
    <cookie-law
      v-if="false"
      class="opacity-25"
      theme="dark-lime"
      storage-type="cookies"
      :cookie-options="{
        domain: 'norskhelseportal.no',
        path: '/',
        secure: true,
      }"
    >
      <div
        slot-scope="props"
        class="d-flex w-100 align-items-center justify-content-between"
      > <div>
          <h5 class="text-white mb-0">{{ $t('The Norsk Helseportal uses cookies to register that your browser has visited our website.') }}
          </h5>
          <a
            href="https://beta.norskhelseportal.no/terms-and-privacy-policy"
            target="_blank"
          >{{ $t('Read our privacy policy here.') }}</a>
        </div>

        <b-button
          variant="primary"
          @click="props.accept"
        >
          <span>{{ $t('Accept cookies') }}</span>
        </b-button>
      </div>
    </cookie-law>
  </div>
</template>

<script>
// This will be populated in `beforeCreate` hook
import { $themeColors, $themeBreakpoints, $themeConfig } from '@themeConfig'
import { provideToast } from 'vue-toastification/composition'
import { watch } from '@vue/composition-api'
import useAppConfig from '@core/app-config/useAppConfig'
import { BButton } from 'bootstrap-vue'

import { useWindowSize, useCssVar } from '@vueuse/core'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import CookieLaw from 'vue-cookie-law'

import store from '@/store'

const LayoutVertical = () => import('@/layouts/vertical/LayoutVertical.vue')
const LayoutHorizontal = () => import('@/layouts/horizontal/LayoutHorizontal.vue')
const LayoutFull = () => import('@/layouts/full/LayoutFull.vue')
const Loader = () => import('@/components/Loader.vue')

export default {
  components: {

    // Layouts
    LayoutHorizontal,
    LayoutVertical,
    LayoutFull,

    Loader,
    CookieLaw,
    BButton,

  },
  data: () => ({
    loading: false,
  }),
  // ! We can move this computed: layout & contentLayoutType once we get to use Vue 3
  // Currently, router.currentRoute is not reactive and doesn't trigger any change
  computed: {
    layout() {
      if (this.$route.meta.layout === 'full') return 'layout-full'
      return `layout-${this.contentLayoutType}`
    },
    contentLayoutType() {
      return this.$store.state.appConfig.layout.type
    },
  },
  beforeMount() {
    this.$store.dispatch('settings/fetchFrontendSettings')

    EventBus.$on('showLoader', () => { this.loading = true })
    EventBus.$on('hideLoader', () => { this.loading = false })
    EventBus.$on('updateToken', token => this.$store.dispatch('auth/setAccessToken', token))
    EventBus.$on('logout', () => {
      this.$store.dispatch('auth/logout')
      this.$router.push({ name: 'home' })
    })
  },
  beforeCreate() {
    // Set colors in theme
    const colors = ['primary', 'secondary', 'success', 'info', 'warning', 'danger', 'light', 'dark']

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = colors.length; i < len; i++) {
      $themeColors[colors[i]] = useCssVar(`--${colors[i]}`, document.documentElement).value.trim()
    }

    // Set Theme Breakpoints
    const breakpoints = ['xs', 'sm', 'md', 'lg', 'xl']

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = breakpoints.length; i < len; i++) {
      $themeBreakpoints[breakpoints[i]] = Number(useCssVar(`--breakpoint-${breakpoints[i]}`, document.documentElement).value.slice(0, -2))
    }

    // Set RTL
    const { isRTL } = $themeConfig.layout
    document.documentElement.setAttribute('dir', isRTL ? 'rtl' : 'ltr')

    store.subscribe((mutation, state) => {
      const showToast = (text, variant = 'danger', icon = 'AlertTriangleIcon') => {
        this.$toast({
          component: ToastificationContent,
          props: { icon, text, variant },
        })
      }

      if (mutation.type === 'setError') {
        if (typeof state.error.value === 'string') {
          showToast(state.error.value, state.error.type)
        }
        store.commit('clearErrors')
      }

      if (mutation.type === 'setFormErrors') {
        if (typeof state.formErrors === 'object') {
          state.formErrors.forEach(validationErrors => {
            validationErrors[1].forEach(validationErr => { showToast(validationErr, 'warning') })
          })
        }
        store.commit('clearErrors')
      }
    })
  },
  mounted() {
    if (this.$store.getters['auth/isAuthenticated']) {
      this.$store.dispatch('auth/getUserInfo')
      if (window.location.pathname === '/' && !('id' in this.$store.getters['auth/user'].active_business_profile)) {
        this.$router.push('feed')
      }
    }
  },
  setup() {
    const { skin, skinClasses } = useAppConfig()

    // If skin is dark when initialized => Add class to body
    if (skin.value === 'dark') document.body.classList.add('dark-layout')

    // Provide toast for Composition API usage
    // This for those apps/components which uses composition API
    // Demos will still use Options API for ease
    provideToast({
      hideProgressBar: true,
      closeOnClick: false,
      closeButton: false,
      icon: false,
      timeout: 3000,
      transition: 'Vue-Toastification__fade',
    })

    // Set Window Width in store
    store.commit('app/UPDATE_WINDOW_WIDTH', window.innerWidth)
    const { width: windowWidth } = useWindowSize()
    watch(windowWidth, val => {
      store.commit('app/UPDATE_WINDOW_WIDTH', val)
    })

    return {
      skinClasses,
    }
  },
}
</script>
