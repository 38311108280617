import Cookie from 'cookie'
import Cookies from 'js-cookie'
import jwtDecode from 'jwt-decode'
import axiosIns from '../../libs/axios'

function isJWTValid(token) {
  if (!token) {
    return false
  }

  const jwtData = jwtDecode(token) || {}
  const expires = jwtData.exp || 0

  return (new Date().getTime() / 1000) < expires
}

export default {
  namespaced: true,
  state: {
    accessToken: null,
    user: {},
  },
  getters: {
    isAuthenticated: state => Boolean(state.accessToken),
    token: state => state.accessToken,
    user: state => state.user,
    permissions: state => state.user?.permissions,
  },
  mutations: {
    setAccessToken(state, token) {
      state.accessToken = token
    },
    setUser(state, user) {
      state.user = user
    },
    setAvatar(state, link) {
      state.user = { ...state.user, profile_avatar: link }
    },
    setProfileBackground(state, link) {
      state.user = { ...state.user, profile_background: link }
    },
    setUserInfo(state, user) {
      state.user = { ...user }
    },
    setEmail(state, email) {
      state.user.email = email
    },
    clearToken(state) {
      state.accessToken = null
    },
    clearUser(state) {
      state.user = {}
    },
  },
  actions: {
    async login({ dispatch }, formData) {
      try {
        const { data: { access_token, data: user } } = await axiosIns.post('/user/login', formData)
        dispatch('setUser', user)
        dispatch('setAccessToken', access_token)
        return user.last_login_date
      } catch (e) {
        dispatch('errorHandler', e, { root: true })
        throw e
      }
    },
    async forgotPassword({ dispatch }, formData) {
      try {
        return await axiosIns.post('/user/forgot-password', formData)
      } catch (e) {
        dispatch('errorHandler', e, { root: true })
        throw e
      }
    },
    async getUserInfo({ commit, dispatch }) {
      try {
        const { data } = await axiosIns.post('/user')
        if (data !== undefined) {
          commit('setUserInfo', data)
          localStorage.setItem('user', JSON.stringify(data))
        }
      } catch (e) {
        dispatch('errorHandler', e, { root: true })
        throw e
      }
    },
    changeUserBackgroundImg({ dispatch }, file) {
      return new Promise(resolve => {
        const formData = new FormData()
        formData.append('image', file)
        axiosIns.post('/user/upload/profile-background', formData)
          .then(response => {
            const { data: { profile_background } } = response
            dispatch('updateProfileBackground', profile_background)
            resolve(response)
          })
          .catch(e => {
            dispatch('errorHandler', e, { root: true })
          })
      })
    },
    changeUserAvatar({ dispatch }, file) {
      return new Promise(resolve => {
        const formData = new FormData()
        formData.append('image', file)
        axiosIns.post('/user/upload/profile-avatar', formData)
          .then(response => {
            const { data: { profile_avatar } } = response
            dispatch('updateAvatar', profile_avatar)
            resolve(response)
          })
          .catch(e => {
            dispatch('errorHandler', e, { root: true })
          })
      })
    },
    async changeUserEmail({ commit, dispatch }, data) {
      try {
        const response = await axiosIns.post('/user/change-email', data)
        commit('setEmail', data.email)
        return response
      } catch (e) {
        dispatch('errorHandler', e, { root: true })
        throw e
      }
    },
    async changeProfileInfo({ commit, dispatch }, data) {
      try {
        const response = await axiosIns.put('/user/profile-information', data)
        commit('setUserInfo', response.data)
        return response
      } catch (e) {
        dispatch('errorHandler', e, { root: true })
        throw e
      }
    },
    changeUserPassword({ dispatch }, data) {
      return new Promise(resolve => {
        axiosIns.put('/user/change-password', data)
          .then(response => {
            resolve(response)
          })
          .catch(e => {
            dispatch('errorHandler', e, { root: true })
          })
      })
    },
    async changeUserNotificationSettings({ dispatch }, settings) {
      try {
        const response = await axiosIns.put('user/settings/notification', settings)
        dispatch('getUserInfo')
        return response
      } catch (e) {
        dispatch('errorHandler', e, { root: true })
        throw e
      }
    },
    async createIdentificationSession({ commit }) {
      try {
        return await axiosIns.get('/signicat/create-session')
      } catch (e) {
        commit('setError', e, { root: true })
        throw e
      }
    },
    async retrieveIdentificationSession({ commit }, id) {
      try {
        return await axiosIns.get(`/signicat/retrieve-session/${id}`)
      } catch (e) {
        commit('setError', e, { root: true })
        throw e
      }
    },
    async createUser({ dispatch }, { id, data }) {
      try {
        const { data: { access_token, data: user } } = await axiosIns.post(`/signicat/create-user/${id}`, data)
        dispatch('setUser', user)
        dispatch('setAccessToken', access_token)
      } catch (e) {
        dispatch('errorHandler', e, { root: true })
        throw e
      }
    },
    async confirmUserEmail({ dispatch }, data) {
      try {
        return await axiosIns.put('/user/confirm-changeable-email', data)
      } catch (e) {
        dispatch('errorHandler', e, { root: true })
        throw e
      }
    },
    async resetPassword({ dispatch }, data) {
      try {
        return await axiosIns.put('/user/reset-password', data)
      } catch (e) {
        dispatch('errorHandler', e, { root: true })
        throw e
      }
    },
    async changeProfile({ dispatch }, id) {
      try {
        return await axiosIns.post(`/user/available-business-profile/${id}/auth-as`)
      } catch (e) {
        dispatch('errorHandler', e, { root: true })
        throw e
      }
    },
    async logOutFromBusinessProfile({ dispatch }) {
      try {
        return await axiosIns.delete('/user/active-business-profiles')
      } catch (e) {
        dispatch('errorHandler', e, { root: true })
        throw e
      }
    },
    initState({ dispatch }) {
      const cookieStr = process.browser
        ? document.cookie
        : this.app.context.req.headers.cookie

      const cookies = Cookie.parse(cookieStr || '') || {}
      const { token } = cookies
      const user = JSON.parse(localStorage.getItem('user'))

      if (token && user) {
        dispatch('setAccessToken', token)
        dispatch('setUser', user)
      }
    },
    setAccessToken({ commit }, token) {
      commit('setAccessToken', token)
      Cookies.set('token', token)
    },
    setUser({ commit }, user) {
      commit('setUser', user)
      localStorage.setItem('user', JSON.stringify(user))
    },
    updateAvatar({ commit, dispatch }, link) {
      commit('setAvatar', link)
      dispatch('updateUserStorage')
    },
    updateProfileBackground({ commit, dispatch }, link) {
      commit('setProfileBackground', link)
      dispatch('updateUserStorage')
    },
    updateUserStorage({ getters }) {
      localStorage.setItem('user', JSON.stringify(getters.user))
    },
    logout({ commit, state }) {
      if (state.accessToken && isJWTValid(state.accessToken)) {
        axiosIns.post('/user/logout')
      }
      commit('clearToken')
      commit('clearUser')
      Cookies.remove('token')
      localStorage.removeItem('user')
    },
    async sendDeletePageRequest({ dispatch }, payload) {
      try {
        const res = await axiosIns.delete('/user', { data: { reason_to_delete: payload.reason_to_delete } })
        return res
      } catch (e) {
        dispatch('errorHandler', e, { root: true })
        throw e
      }
    },
  },
}
