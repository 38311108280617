import i18n from '@/libs/i18n/index'
import auth from '@/router/middlewares/auth'
import noCompanyPage from '@/router/middlewares/no_company_page'
import noHPRConfirmed from '@/router/middlewares/no_hpr_confirmed'
import onReview from '@/router/middlewares/in_review'

export default [
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/Home.vue'),
    meta: {
      pageTitle: i18n.t('Home'),
      breadcrumb: [
        {
          text: i18n.t('Home'),
          active: true,
        },
      ],
    },
  },
  {
    path: '/second-page',
    name: 'second-page',
    component: () => import('@/views/SecondPage.vue'),
    meta: {
      pageTitle: i18n.t('Second Page'),
      breadcrumb: [
        {
          text: i18n.t('Second Page'),
          active: true,
        },
      ],
      middleware: [
        auth,
      ],
    },
  },
  {
    path: '/signicat-registration-callback',
    name: 'signicat-reg-callback',
    component: () => import('@/views/signicat/SignUpCallback.vue'),
    meta: {
      layout: 'full',
      pageTitle: i18n.t('Signicat identity'),
      breadcrumb: [
        {
          text: i18n.t('Signicat identity'),
          active: true,
        },
      ],
    },
  },
  {
    path: '/profile',
    name: 'profile_proxy',
    component: () => import('@/views/my_page/ProfilePageProxy.vue'),
    meta: {
      pageTitle: i18n.t('Profile Page'),
      breadcrumb: [
        {
          text: i18n.t('Profile Page'),
          active: true,
        },
      ],
      middleware: [
        auth,
        onReview,
      ],
    },
  },
  {
    path: '/profile/:slug',
    name: 'profile',
    component: () => import('@/views/my_page/ProfilePage.vue'),
    meta: {
      pageTitle: i18n.t('Profile Page'),
      breadcrumb: [
        {
          text: i18n.t('Profile Page'),
          active: true,
        },
      ],
      middleware: [
        auth,
        onReview,
        noCompanyPage,
      ],
    },
  },
  {
    path: '/my_courses',
    name: 'own_courses',
    component: () => import('@/views/my_page/MyCourses.vue'),
    meta: {
      pageTitle: i18n.t('My Courses'),
      breadcrumb: [
        {
          text: i18n.t('My Courses'),
          active: true,
        },
      ],
      middleware: [
        auth,
        onReview,
      ],
    },
  },
  {
    path: '/my_courses/:id',
    name: 'own_courses',
    component: () => import('@/views/my_page/my_courses/UpdateCourse.vue'),
    meta: {
      pageTitle: i18n.t('Update Course'),
      breadcrumb: [
        {
          text: i18n.t('My Courses'),
          active: false,
          to: { name: 'own_courses' },
        },
        {
          text: i18n.t('Update'),
          active: true,
        },
      ],
      middleware: [
        auth,
        onReview,
      ],
    },
  },
  {
    path: '/my_jobs',
    name: 'my_jobs',
    component: () => import('@/views/my_page/MyJobs.vue'),
    meta: {
      pageTitle: i18n.t('My Jobs'),
      breadcrumb: [
        {
          text: i18n.t('My Jobs'),
          active: true,
        },
      ],
      middleware: [
        auth,
        onReview,
      ],
    },
  },
  {
    path: '/profile_company',
    name: 'profile_company_proxy',
    component: () => import('@/views/my_page/ProfileCompanyProxy.vue'),
    meta: {
      pageTitle: 'Company profile',
      middleware: [
        auth,
        onReview,
      ],
    },
  },
  {
    path: '/profile_company/:slug',
    name: 'profile_company',
    component: () => import('@/views/my_page/ProfileCompany.vue'),
    meta: {
      pageTitle: 'Company profile',
      middleware: [
        auth,
        onReview,
      ],
    },
  },
  {
    path: '/profile_company_manage/:id?',
    name: 'profile_company_manage',
    component: () => import('@/views/my_page/ProfileCompanyManage.vue'),
    meta: {
      pageTitle: 'Manage company profile',
      middleware: [
        auth,
        onReview,
      ],
    },
  },
  {
    path: '/profile_settings/:slug',
    name: 'profile_settings',
    component: () => import('@/views/my_page/ProfileSettings.vue'),
    meta: {
      pageTitle: 'Profile settings',
      middleware: [
        auth,
        onReview,
      ],
      breadcrumb: [
        {
          text: i18n.t('Profile page'),
          active: false,
        },
        {
          text: i18n.t('Profile settings'),
          active: false,
        },
      ],
    },
  },
  {
    path: '/archived_posts/:slug',
    name: 'archived_posts',
    component: () => import('@/views/my_page/ArchivedPosts.vue'),
    meta: {
      pageTitle: 'Archived posts',
      middleware: [
        auth,
        onReview,
      ],
      breadcrumb: [
        {
          text: i18n.t('Profile page'),
          active: false,
        },
        {
          text: i18n.t('Archived posts'),
          active: false,
        },
      ],
    },
  },
  {
    path: '/post/:id',
    name: 'post',
    component: () => import('@/views/ViewPostPage.vue'),
    meta: {
      pageTitle: 'Post',
      middleware: [
        auth,
        onReview,
      ],
    },
  },
  {
    path: '/my_products',
    name: 'my_products',
    component: () => import('@/views/my_page/MyProducts.vue'),
    meta: {
      pageTitle: i18n.t('My Products'),
      breadcrumb: [
        {
          text: i18n.t('My Products'),
          active: true,
        },
      ],
      middleware: [
        auth,
        onReview,
      ],
    },
  },
  {
    path: '/course',
    name: 'course',
    component: () => import('@/views/Course.vue'),
    meta: {
      pageTitle: i18n.t('Courses'),
      breadcrumb: [
        {
          text: i18n.t('Courses'),
          active: true,
        },
      ],
      middleware: [
        auth,
        onReview,
      ],
    },
    children: [
      {
        path: 'webinares:filter?',
        name: 'course',
        component: () => import('@/views/course/Webinares.vue'),
        meta: {
          pageTitle: i18n.t('Webinars'),
          breadcrumb: [
            {
              text: i18n.t('Courses'),
              active: true,
            },
            {
              text: i18n.t('Webinars'),
              active: true,
            },
          ],
          middleware: [
            auth,
            onReview,
          ],
        },
        query: {
          filter: '{"pageNumber":1,"filterBySpeciality":[],"filterByCity":[],"filterByCounty":[],"search": null,"activeTab":1}',
        },
      },
      {
        path: 'webinares/:id/:type',
        name: 'course',
        component: () => import('@/views/course/Webinares.vue'),
        meta: {
          pageTitle: i18n.t('Webinars'),
          breadcrumb: [
            {
              text: i18n.t('Courses'),
              active: true,
            },
            {
              text: i18n.t('Webinars'),
              active: true,
              to: '/course/webinares',
            },
            {
              text: i18n.t('Details'),
              active: true,
            },
          ],
          middleware: [
            auth,
            onReview,
          ],
        },
      },
      {
        path: 'my_courses:filter?',
        name: 'course',
        component: () => import('@/views/course/MyCourse.vue'),
        meta: {
          pageTitle: i18n.t('MyCourses'),
          breadcrumb: [
            {
              text: i18n.t('Courses'),
              active: true,
            },
            {
              text: i18n.t('MyCourses'),
              active: true,
            },
          ],
          middleware: [
            auth,
            onReview,
          ],
        },
        query: {
          filter: '{"pageNumber":1,"filterBySpeciality":[],"filterByCity":[],"search": null,"activeTab":1}',
        },
      },
    ],
  },
  {
    path: '/course/:new',
    name: 'course',
    component: () => import('@/views/course/NewCourse.vue'),
    meta: {
      pageTitle: i18n.t('New Course'),
      breadcrumb: [
        {
          text: i18n.t('Courses'),
          active: false,
          to: { name: 'course' },
        },
        {
          text: i18n.t('New'),
          active: true,
        },
      ],
      middleware: [
        auth,
        onReview,
      ],
    },
  },
  {
    path: '/jobs:filter?',
    name: 'jobs',
    component: () => import('@/views/Jobs.vue'),
    meta: {
      pageTitle: i18n.t('Jobs'),
      breadcrumb: [
        {
          text: i18n.t('Jobs'),
          active: true,
        },
      ],
      middleware: [
        auth,
        onReview,
      ],
    },
    query: {
      filter: '{"pageNumber":1,"filterBySpeciality":[],"filterByCounty":[],"search": null}',
    },
  },
  {
    path: '/jobs/details/:id',
    name: 'jobs',
    component: () => import('@/views/jobs/JobDetail.vue'),
    meta: {
      pageTitle: i18n.t('Job Details'),
      breadcrumb: [
        {
          text: i18n.t('Jobs'),
          active: false,
          to: { name: 'jobs' },
        },
        {
          text: i18n.t('Details'),
          active: true,
        },
      ],
      middleware: [
        auth,
        onReview,
      ],
    },
  },
  {
    path: '/my_jobs/details/:id',
    name: 'my_jobs',
    component: () => import('@/views/jobs/JobDetail.vue'),
    meta: {
      pageTitle: i18n.t('Job Details'),
      breadcrumb: [
        {
          text: i18n.t('Jobs'),
          active: false,
          to: { name: 'my_jobs' },
        },
        {
          text: i18n.t('Details'),
          active: true,
        },
      ],
      middleware: [
        auth,
        onReview,
      ],
    },
  },
  {
    path: '/jobs/create',
    name: 'jobs',
    component: () => import('@/views/jobs/CreateJob.vue'),
    meta: {
      pageTitle: i18n.t('Create Job'),
      breadcrumb: [
        {
          text: i18n.t('Jobs'),
          active: false,
          to: { name: 'jobs' },
        },
        {
          text: i18n.t('New job'),
          active: true,
        },
      ],
      middleware: [
        auth,
        onReview,
      ],
    },
  },
  {
    path: '/jobs/update/:id',
    name: 'my_jobs',
    component: () => import('@/views/jobs/CreateJob.vue'),
    meta: {
      pageTitle: i18n.t('Update job'),
      breadcrumb: [
        {
          text: i18n.t('My Jobs'),
          active: false,
          to: { name: 'my_jobs' },
        },
        {
          text: i18n.t('Update job'),
          active: true,
        },
      ],
      middleware: [
        auth,
        onReview,
      ],
    },
  },
  {
    path: '/marketplace',
    name: 'marketplace',
    component: () => import('@/views/Marketplace.vue'),
    meta: {
      pageTitle: i18n.t('Marketplace'),
      breadcrumb: [
        {
          text: i18n.t('Marketplace'),
          active: true,
        },
      ],
      middleware: [
        auth,
        onReview,
      ],
    },
  },
  {
    path: '/feed',
    name: 'feed',
    component: () => import('@/views/Feed.vue'),
    meta: {
      pageTitle: i18n.t('Feed'),
      breadcrumb: [
        {
          text: i18n.t('Feed'),
          active: true,
        },
      ],
      middleware: [
        auth,
        onReview,
        noCompanyPage,
      ],
    },
  },
  {
    path: '/marketplace/details/:id',
    name: 'marketplace',
    component: () => import('@/views/marketplace/ProductDetails.vue'),
    meta: {
      pageTitle: i18n.t('Product details'),
      breadcrumb: [
        {
          text: i18n.t('Marketplace'),
          active: false,
          to: { name: 'marketplace' },
        },
        {
          text: i18n.t('Details'),
          active: true,
        },
      ],
      middleware: [
        auth,
        onReview,
      ],
    },
    query: {
      filter: '{"pageNumber":1}',
    },
  },
  {
    path: '/my_products/details/:id',
    name: 'my_products',
    component: () => import('@/views/marketplace/ProductDetails.vue'),
    meta: {
      pageTitle: i18n.t('Product details'),
      breadcrumb: [
        {
          text: i18n.t('Marketplace'),
          active: false,
          to: { name: 'my_products' },
        },
        {
          text: i18n.t('Details'),
          active: true,
        },
      ],
      middleware: [
        auth,
        onReview,
      ],
    },
  },
  {
    path: '/marketplace/new',
    name: 'marketplace',
    component: () => import('@/views/marketplace/AddNewProduct.vue'),
    meta: {
      pageTitle: i18n.t('New product'),
      breadcrumb: [
        {
          text: i18n.t('Marketplace'),
          active: false,
          to: { name: 'marketplace' },
        },
        {
          text: i18n.t('new'),
          active: true,
        },
      ],
      middleware: [
        auth,
        onReview,
      ],
    },
  },
  {
    path: '/my_products/update/:id',
    name: 'my_products',
    component: () => import('@/views/marketplace/AddNewProduct.vue'),
    meta: {
      pageTitle: i18n.t('New product'),
      breadcrumb: [
        {
          text: i18n.t('Marketplace'),
          active: false,
          to: { name: 'my_products' },
        },
        {
          text: i18n.t('new'),
          active: true,
        },
      ],
      middleware: [
        auth,
        onReview,
      ],
    },
  },
  {
    path: '/videos:filter?',
    name: 'videos',
    component: () => import('@/views/Videos.vue'),
    meta: {
      pageTitle: i18n.t('Videos'),
      breadcrumb: [
        {
          text: i18n.t('Videos'),
          active: true,
        },
      ],
      middleware: [
        auth,
        onReview,
        noCompanyPage,
        noHPRConfirmed,
      ],
    },
    query: {
      filter: '{"pageNumber":1}',
    },
  },
  {
    path: '/videos/:id',
    name: 'videos',
    component: () => import('@/views/videos/VideoDetails.vue'),
    meta: {
      pageTitle: i18n.t('Videos'),
      breadcrumb: [
        {
          text: i18n.t('Videos'),
          active: false,
          to: { name: 'videos' },
        },
        {
          text: i18n.t('Details'),
          active: true,
        },
      ],
      middleware: [
        auth,
        onReview,
        noHPRConfirmed,
      ],
    },
  },
  {
    path: '/audio:filter?',
    name: 'audio',
    component: () => import('@/views/AudioArchive.vue'),
    meta: {
      pageTitle: i18n.t('Audio archive'),
      breadcrumb: [
        {
          text: i18n.t('Audio archive'),
          active: true,
        },
      ],
      middleware: [
        auth,
        onReview,
        noCompanyPage,
        noHPRConfirmed,
      ],
    },
    query: {
      filter: '{"pageNumber":1}',
    },
  },
  {
    path: '/audio/:id',
    name: 'audio',
    component: () => import('@/views/audio_archive/AudioDetails.vue'),
    meta: {
      pageTitle: i18n.t('Audio archive'),
      breadcrumb: [
        {
          text: i18n.t('Audio archive'),
          active: false,
          to: { name: 'audio' },
        },
        {
          text: i18n.t('Details'),
          active: true,
        },
      ],
      middleware: [
        auth,
        onReview,
        noHPRConfirmed,
      ],
    },
  },
  {
    path: '/terms-and-privacy-policy',
    name: 'terms-and-privacy-policy',
    component: () => import('@/views/TermsAndPrivacyPolicy.vue'),
    meta: {
      pageTitle: i18n.t('Terms and Privacy Policy'),
      breadcrumb: [
        {
          text: i18n.t('Home'),
          active: true,
        },
      ],
    },
  },
  {
    path: '/about_us',
    name: 'about_us',
    component: () => import('@/views/AboutUs.vue'),
    meta: {
      pageTitle: i18n.t('About Us'),
    },
  },
  {
    path: '/next',
    name: 'next',
    component: () => import('@/views/Next.vue'),
    meta: {
      pageTitle: i18n.t('Next'),
    },
  },
  {
    path: '/pages/:category',
    name: 'pages',
    component: () => import('@/views/Pages.vue'),
    meta: {
      pageTitle: i18n.t('Pages'),
      breadcrumb: [
        {
          text: i18n.t('Pages'),
          active: false,
        },
      ],
      middleware: [
        auth,
        onReview,
        noCompanyPage,
      ],
    },
  },
]
