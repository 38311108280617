/* eslint-disable import/no-cycle */
import axiosIns from '../../libs/axios'

export default {
  namespaced: true,
  state: {
    unreadCount: 0,
    notifications: [],
  },
  getters: {
    unreadCount: state => state.unreadCount,
    notifications: state => state.notifications,
  },
  mutations: {
    setUnreadCount(state, unreadCount) {
      state.unreadCount = unreadCount
    },
    setNotifications(state, notifications) {
      state.notifications = notifications
    },
  },
  actions: {
    async fetchNotifications({ dispatch, commit }) {
      try {
        const { data: res } = await axiosIns.get('/user-notifications')
        if (res) {
          const notifications = Object.keys(res.notifications).map(key => res.notifications[key])
          commit('setNotifications', notifications.splice(0, notifications.length - 1))
          commit('setUnreadCount', res.unreadCount)
        }
      } catch (e) {
        dispatch('errorHandler', e, { root: true })
        throw e
      }
    },
    async markNotificationAsRead({ dispatch }, ids) {
      try {
        await axiosIns.put('/user-notifications', {
          ids,
        })
      } catch (e) {
        dispatch('errorHandler', e, { root: true })
        throw e
      }
    },
  },
}
