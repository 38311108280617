import { env } from '@/config/config'

export default function (array, locale) {
  return array.map(el => {
    const obj = { ...el }
    if (Object.prototype.hasOwnProperty.call(el.name, locale)) {
      obj.name = el.name[locale]
      return obj
    }
    if (Object.prototype.hasOwnProperty.call(el.name, env.fallbackLocale)) {
      obj.name = el.name[env.fallbackLocaleale]
      return obj
    }
    if (typeof obj.name !== 'string' && Object.keys(obj.name).length) {
      obj.name = el.name[Object.keys(obj.name)[0]]
      return obj
    }
    return el
  })
}
