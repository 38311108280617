/* eslint-disable import/no-cycle */
import axiosIns from '../../libs/axios'

export default {
  namespaced: true,
  state: {
    search: ' ',
    postSearch: ' ',
    followers: [],
    company: null,
    companies: [],
    companiesPagination: null,
    companiesPageNumber: 1,
    companyPosts: [],
    companyPostsPagination: null,
    companyPostsPageNumber: 1,
    companyPostsSearch: '',
    companiesSearch: '',
    archivedPosts: [],
  },
  getters: {
    search: state => state.search,
    postSearch: state => state.postSearch,
    companiesSearch: state => state.companiesSearch,
    followers: state => state.followers,
    company: state => state.company,
    companies: state => state.companies,
    companiesPagination: state => state.companiesPagination,
    companiesPageNumber: state => state.companiesPageNumber,
    companyPosts: state => state.companyPosts,
    companyPostsPagination: state => state.companyPostsPagination,
    companyPostsPageNumber: state => state.companyPostsPageNumber,
    companyPostsSearch: state => state.companyPostsSearch,
    archivedPosts: state => state.archivedPosts,
  },
  mutations: {
    setFollowers(state, followers) {
      state.followers = followers
    },
    setCompany(state, company) {
      state.company = company
    },
    setCompanies(state, companies) {
      state.companies = companies
    },
    setSearch(state, search) {
      state.search = search
    },
    setPostSearch(state, postSearch) {
      state.postSearch = postSearch
    },
    setCompaniesSearch(state, companiesSearch) {
      state.companiesSearch = companiesSearch
    },
    setCompaniesPagination(state, companiesPagination) {
      state.companiesPagination = companiesPagination
    },
    setCompaniesPageNumber(state, companiesPageNumber) {
      state.companiesPageNumber = companiesPageNumber
    },
    setCompanyPosts(state, companyPosts) {
      state.companyPosts = [...companyPosts]
    },
    setCompanyPostsPagination(state, companyPostsPagination) {
      state.companyPostsPagination = companyPostsPagination
    },
    setCompanyPostsPageNumber(state, companyPostsPageNumber) {
      state.companyPostsPageNumber = companyPostsPageNumber
    },
    setCompanyPostsSearch(state, companyPostsSearch) {
      state.companyPostsSearch = companyPostsSearch
    },
    setArchivedPosts(state, archivedPosts) {
      state.archivedPosts = archivedPosts
    },
  },
  actions: {
    async fetchFollowers({ dispatch, commit, state }, slug) {
      try {
        const { data: res } = await axiosIns.get(`/partner-companies/followers/${slug}?name=${state.search}`)
        if (res.data) {
          commit('setFollowers', res.data)
        }
      } catch (e) {
        dispatch('errorHandler', e, { root: true })
        throw e
      }
    },
    async fetchCompany({ dispatch, commit }, slug) {
      try {
        const res = await axiosIns.get(`/partner-companies/${slug}/show`)
        if (res.data) {
          commit('setCompany', res.data.data)
        }
        return res
      } catch (e) {
        dispatch('errorHandler', e, { root: true })
        throw e
      }
    },
    async fetchCompanies({ dispatch, commit, state }, categories) {
      try {
        const { data: res } = await axiosIns.get(`/partner-companies?page%5Bnumber%5D=${state.companiesPageNumber}`, {
          params: {
            categories,
            name: state.companiesSearch,
          },
        })
        if (res.data) {
          commit('setCompanies', res.data)
          commit('setCompaniesPagination', res.meta.pagination)
        }
      } catch (e) {
        dispatch('errorHandler', e, { root: true })
        throw e
      }
    },
    async sendFollowInvitation({ dispatch }, payload) {
      try {
        return await axiosIns.post(`/partner-company/${payload.slug}/follow-invitation`, {
          users: payload.users,
        })
      } catch (e) {
        dispatch('errorHandler', e, { root: true })
        throw e
      }
    },
    async setHideProfile({ dispatch }, payload) {
      try {
        const res = await axiosIns.patch(`/partner-companies/${payload.slug}/settings`, {
          is_published: payload.isPublished,
        })
        return res
      } catch (e) {
        dispatch('errorHandler', e, { root: true })
        throw e
      }
    },
    async setAllowComments({ dispatch }, payload) {
      try {
        const res = await axiosIns.patch(`/partner-companies/${payload.slug}/settings`, {
          is_comments_allowed: payload.isCommentsAllowed,
        })
        return res
      } catch (e) {
        dispatch('errorHandler', e, { root: true })
        throw e
      }
    },
    async sendDeletePageRequest({ dispatch }, payload) {
      try {
        const res = await axiosIns.delete(`/partner-companies/${payload.slug}`, { data: { reason_to_delete: payload.reason_to_delete } })
        return res
      } catch (e) {
        dispatch('errorHandler', e, { root: true })
        throw e
      }
    },
    async fetchPosts({ dispatch, commit, state }, payload) {
      try {
        const { data: res } = await axiosIns.get(`/partner-company-posts/${payload.slug}?page=${state.companyPostsPageNumber}&search=${state.postSearch}&archive=${payload.archive}`)

        if (res.data && res.meta) {
          commit(payload.archive ? 'setArchivedPosts' : 'setCompanyPosts', res.data)
          commit('setCompanyPostsPagination', res.meta.pagination)
        }
      } catch (e) {
        dispatch('errorHandler', e, { root: true })
        throw e
      }
    },
  },
}
