import axiosIns from '../../libs/axios'

export default {
  namespaced: true,
  state: {
    products: null,
    selectedProduct: null,
    search: null,
    pageNumber: 1,
    filterByStatus: 1,
    sort: 'price',
    direction: 'asc',
  },
  getters: {
    products: state => state.products,
    selectedProduct: state => state.selectedProduct,
    search: state => state.search,
    pageNumber: state => state.pageNumber,
    filterByStatus: state => state.filterByStatus,
    sort: state => state.sort,
    direction: state => state.direction,
    query(state) {
      return {
        search: state.search,
        pageNumber: state.pageNumber,
        filterByStatus: state.filterByStatus,
        sort: state.sort,
        direction: state.direction,
      }
    },
  },
  mutations: {
    setProducts(state, products) {
      state.products = products
    },
    setSelectedProduct(state, selectedProduct) {
      state.selectedProduct = selectedProduct
    },
    setSearch(state, search) {
      if (search) state.search = search
      else state.search = null
    },
    setPageNumber(state, pageNumber) {
      state.pageNumber = pageNumber
    },
    setFilterByStatus(state, filterByStatus) {
      state.filterByStatus = filterByStatus
    },
    setSort(state, sort) {
      state.sort = sort
    },
    setDirection(state, direction) {
      state.direction = direction
    },
    setQuery(state, query) {
      state.pageNumber = query.pageNumber
      state.search = query.search
      state.filterByStatus = query.filterByStatus
      state.sort = query.sort
      state.direction = query.direction
    },
  },
  actions: {
    async fetchProducts({
      dispatch, commit, state, getters,
    }) {
      try {
        const { data: products } = await axiosIns.get(`/products?page%5Bnumber%5D=${state.pageNumber}`,
          {
            params: getters.query,
          })
        commit('setProducts', products)
      } catch (e) {
        dispatch('errorHandler', e, { root: true })
        throw e
      }
    },
    async fetchProductById({ dispatch, commit }, id) {
      try {
        const { data: product } = await axiosIns.get(`/products/${id}`)
        commit('setSelectedProduct', product)
      } catch (e) {
        dispatch('errorHandler', e, { root: true })
        throw e
      }
    },
    async createProducts({ dispatch }, product) {
      try {
        return await axiosIns({
          method: 'post',
          url: '/my/products',
          data: product,
          headers: { 'Content-Type': 'multipart/form-data' },
        })
      } catch (e) {
        dispatch('errorHandler', e, { root: true })
        throw e
      }
    },
    async markAsViewed({ dispatch }, id) {
      try {
        await axiosIns.get(`/products/${id}/mark-as-viewed`)
      } catch (e) {
        dispatch('errorHandler', e, { root: true })
        throw e
      }
    },
  },
}
