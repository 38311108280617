import axiosIns from '../../libs/axios'

export default {
  namespaced: true,
  state: {
    academicLevels: [],
  },
  getters: {
    academicLevels: state => state.academicLevels,
  },
  mutations: {
    setAcademicLevels(state, academicLevels) {
      state.academicLevels = academicLevels
    },
  },
  actions: {
    async fetchAcademicLevels({ dispatch, commit }) {
      try {
        const { data: { data: academicLevels } } = await axiosIns.get('/academic-levels')
        commit('setAcademicLevels', academicLevels)
      } catch (e) {
        dispatch('errorHandler', e, { root: true })
        throw e
      }
    },
  },
}
