const env = {
  baseUrl: process.env.VUE_APP_API_URL,
  locale: process.env.VUE_APP_LOCALE || 'no',
  fallbackLocale: process.env.VUE_APP_FALLBACK_LOCALE || 'en',
}

const permissions = {
  COURSE_CREATE: 'course-create',
  COURSE_DELETE: 'course-delete',
  COURSE_READ: 'course-read',
  COURSE_UPDATE: 'course-update',
  JOBS_CREATE: 'jobs-create',
  JOBS_DELETE: 'jobs-delete',
  JOBS_READ: 'jobs-read',
  JOBS_UPDATE: 'jobs-update',
  MARKET_CREATE: 'market-create',
  MARKET_DELETE: 'market-delete',
  MARKET_READ: 'market-read',
  MARKET_UPDATE: 'market-update',
  VIDEO_READ: 'video-viewer-read',
  AUDIO_READ: 'audio-viewer-read',
}

export { env, permissions }
