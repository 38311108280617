/* eslint-disable import/no-cycle */
import axiosIns from '../../libs/axios'

export default {
  namespaced: true,
  state: {
    course: null,
    selectedCourse: null,
    search: null,
    filterBySpeciality: [],
    filterByWorkplace: [],
    filterByType: [],
    pageNumber: 1,
  },
  getters: {
    course: state => state.course,
    selectedCourse: state => state.selectedCourse,
    search: state => state.search,
    filterBySpeciality: state => state.filterBySpeciality,
    filterByWorkplace: state => state.filterByWorkplace,
    filterByType: state => state.filterByType,
    pageNumber: state => state.pageNumber,
    query(state) {
      return {
        search: state.search,
        filterBySpeciality: state.filterBySpeciality,
        filterByWorkplace: state.filterByWorkplace,
        filterByType: state.filterByType,
        pageNumber: state.pageNumber,
      }
    },
  },
  mutations: {
    setCourse(state, course) {
      state.course = course
    },
    setSelectedCourse(state, selectedCourse) {
      state.selectedCourse = selectedCourse
    },
    setSearch: (state, search) => {
      state.search = search
    },
    setFilterByType: (state, setFilterByType) => {
      state.setFilterByType = setFilterByType
    },
    setFilterByWorkplace: (state, filterByWorkplace) => {
      state.filterByWorkplace = filterByWorkplace
    },
    setFilterBySpeciality: (state, seafilterBySpecialityrch) => {
      state.filterBySpeciality = seafilterBySpecialityrch
    },
    setPageNumber: (state, pageNumber) => {
      state.pageNumber = pageNumber
    },
    setQuery(state, query) {
      state.pageNumber = query.pageNumber
      state.filterByType = query.filterByType
      state.filterBySpeciality = query.filterBySpeciality
      state.filterByWorkplace = query.filterByWorkplace
      state.search = query.search
    },
    clearFilters(state) {
      state.search = null
      state.filterByType = []
      state.filterBySpeciality = []
      state.pageNumber = 1
      state.filterByWorkplace = []
    },
  },
  actions: {
    async fetchCourses({ dispatch, commit, state }, creatorType) {
      const query = {
        search: state.search,
        filterBySpeciality: state.filterBySpeciality,
        filterByWorkplace: state.filterByWorkplace,
        filterByType: state.filterByType,
      }
      if (creatorType) {
        query.creator_type = creatorType
      }
      try {
        const { data: course } = await axiosIns.get(`/my/courses?page%5Bnumber%5D=${state.pageNumber}`, {
          params: query,
        })
        commit('setCourse', course)
      } catch (e) {
        dispatch('errorHandler', e, { root: true })
        throw e
      }
    },
    async fetchCourseById({ dispatch, commit }, id) {
      try {
        const { data: course } = await axiosIns.get(`/my/courses/${id}`)
        commit('setSelectedCourse', course)
      } catch (e) {
        dispatch('errorHandler', e, { root: true })
        throw e
      }
    },
    async createCourse({ dispatch }, course) {
      try {
        return await axiosIns.post('/my/courses', course)
      } catch (e) {
        dispatch('errorHandler', e, { root: true })
        throw e
      }
    },
    async updateCourse({ dispatch }, payload) {
      try {
        return await axiosIns.put(`/my/courses/${payload.id}`, payload.course)
      } catch (e) {
        dispatch('errorHandler', e, { root: true })
        throw e
      }
    },
  },
}
