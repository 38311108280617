import i18n from '@/libs/i18n/index'
import guest from '@/router/middlewares/guest'
import auth from '@/router/middlewares/auth'

export default [
  {
    path: '/user-verification/:id',
    name: 'user-verification',
    component: () => import('@/views/UserVerification.vue'),
    meta: {
      layout: 'full',
      pageTitle: i18n.t('User verification'),
      breadcrumb: [
        {
          text: i18n.t('Home'),
          active: true,
        },
      ],
      middleware: [
        guest,
      ],
    },
  },
  {
    path: '/professional_experience',
    name: 'professional_experience',
    component: () => import('@/views/AddProfessionalExperience.vue'),
    meta: {
      layout: 'full',
      pageTitle: i18n.t('Professional experience'),
      breadcrumb: [
        {
          text: i18n.t('Home'),
          active: true,
        },
      ],
      middleware: [
        auth,
      ],
    },
  },
  {
    path: '/confirm-changeable-email',
    name: 'confirm-changeable-email',
    component: () => import('@/views/ConfirmChangeableEmail.vue'),
    meta: {
      layout: 'full',
      pageTitle: i18n.t('User change email'),
      breadcrumb: [
        {
          text: i18n.t('Home'),
          active: true,
        },
      ],
    },
  },
  {
    path: '/reset-password',
    name: 'reset-password',
    component: () => import('@/views/ResetPasswordPage.vue'),
    meta: {
      layout: 'full',
      pageTitle: i18n.t('User reset password'),
      breadcrumb: [
        {
          text: i18n.t('Home'),
          active: true,
        },
      ],
    },
  },
]
