import axiosIns from '../../libs/axios'

export default {
  namespaced: true,
  state: {
    audios: [],
    pageNumber: 1,
    selectedAudio: null,
    categories: [],
    selectedCategories: [],
    search: null,
  },
  getters: {
    audios: state => state.audios,
    selectedAudio: state => state.selectedAudio,
    pageNumber: state => state.pageNumber,
    categories: state => state.categories,
    selectedCategories: state => state.selectedCategories,
    search: state => state.search,
    query(state) {
      return {
        pageNumber: state.pageNumber,
        selectedCategories: state.selectedCategories,
        search: state.search,
      }
    },
  },
  mutations: {
    setAudios(state, audios) {
      state.audios = audios
    },
    setSelectedAudio(state, selectedAudio) {
      state.selectedAudio = selectedAudio
    },
    setPageNumber(state, pageNumber) {
      state.pageNumber = pageNumber
    },
    setCategories(state, categories) {
      state.categories = categories
    },
    setSearch(state, search) {
      state.search = search
    },
    setSelectedCategories(state, selectedCategories) {
      state.selectedCategories = selectedCategories
    },
    setQuery(state, query) {
      state.pageNumber = query.pageNumber
      state.selectedCategories = query.selectedCategories
      state.search = query.search
      state.direction = query.direction
    },
    clearFilters(state) {
      state.pageNumber = 1
      state.selectedCategories = []
      state.search = null
    },
  },
  actions: {
    async fetchAudios({
      dispatch, commit, state, getters,
    }) {
      commit('setAudios', [])
      try {
        const { data: audios } = await axiosIns.get(`/audio?page%5Bnumber%5D=${state.pageNumber}`, {
          params: getters.query,
        })
        commit('setAudios', audios)
      } catch (e) {
        dispatch('errorHandler', e, { root: true })
        throw e
      }
    },
    async fetchAudioById({ dispatch, commit }, id) {
      try {
        const { data: audio } = await axiosIns.get(`/audio/${id}`)
        commit('setSelectedAudio', audio)
      } catch (e) {
        dispatch('errorHandler', e, { root: true })
        throw e
      }
    },
    async fetchCategories({ dispatch, commit }) {
      try {
        const { data: { data: categories } } = await axiosIns.get('/audio-categories')
        commit('setCategories', categories)
      } catch (e) {
        dispatch('errorHandler', e, { root: true })
        throw e
      }
    },
  },
}
