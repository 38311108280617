/* eslint-disable import/no-cycle */
import axiosIns from '../../libs/axios'

export default {
  namespaced: true,
  state: {
    jobs: null,
    selectedJob: null,
    search: null,
    filterBySpeciality: [],
    filterByWorkplace: [],
    filterByCounty: [],
    pageNumber: 1,
  },
  getters: {
    jobs: state => state.jobs,
    selectedJob: state => state.selectedJob,
    search: state => state.search,
    filterBySpeciality: state => state.filterBySpeciality,
    filterByWorkplace: state => state.filterByWorkplace,
    filterByCounty: state => state.filterByCounty,
    pageNumber: state => state.pageNumber,
    query(state) {
      return {
        search: state.search,
        filterBySpeciality: state.filterBySpeciality,
        filterByWorkplace: state.filterByWorkplace,
        filterByCounty: state.filterByCounty,
        pageNumber: state.pageNumber,
      }
    },
  },
  mutations: {
    setJobs(state, jobs) {
      state.jobs = jobs
    },
    setSelectedJob(state, selectedJob) {
      state.selectedJob = selectedJob
    },
    setSearch: (state, search) => {
      state.search = search
    },
    setFilterByCounty: (state, setFilterByCounty) => {
      state.setFilterByCounty = setFilterByCounty
    },
    setFilterByWorkplace: (state, filterByWorkplace) => {
      state.filterByWorkplace = filterByWorkplace
    },
    setFilterBySpeciality: (state, filterBySpeciality) => {
      state.filterBySpeciality = filterBySpeciality
    },
    setPageNumber: (state, pageNumber) => {
      state.pageNumber = pageNumber
    },
    setQuery(state, query) {
      state.pageNumber = query.pageNumber
      state.filterByCounty = query.filterByCounty
      state.filterBySpeciality = query.filterBySpeciality
      state.filterByWorkplace = query.filterByWorkplace
      state.search = query.search
    },
    clearFilters(state) {
      state.search = null
      state.filterByCounty = []
      state.filterBySpeciality = []
      state.pageNumber = 1
      state.filterByWorkplace = []
    },
  },
  actions: {
    async fetchJobs({ dispatch, commit, state }) {
      const query = {
        search: state.search,
        filterBySpeciality: state.filterBySpeciality,
        filterByWorkplace: state.filterByWorkplace,
        filterByCounty: state.filterByCounty,
      }
      try {
        const { data: course } = await axiosIns.get(`/my/jobs?page%5Bnumber%5D=${state.pageNumber}`, {
          params: query,
        })
        commit('setJobs', course)
      } catch (e) {
        dispatch('errorHandler', e, { root: true })
        throw e
      }
    },
    async fetchJobById({ dispatch, commit }, id) {
      try {
        const { data: course } = await axiosIns.get(`/my/jobs/${id}`)
        commit('setSelectedJob', course)
      } catch (e) {
        dispatch('errorHandler', e, { root: true })
        throw e
      }
    },
    async createJob({ dispatch }, job) {
      try {
        return await axiosIns.post('/my/jobs', job)
      } catch (e) {
        dispatch('errorHandler', e, { root: true })
        throw e
      }
    },
    async updateJob({ dispatch }, payload) {
      try {
        return await axiosIns.put(`/my/jobs/${payload.id}`, payload.job)
      } catch (e) {
        dispatch('errorHandler', e, { root: true })
        throw e
      }
    },
  },
}
