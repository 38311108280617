import axiosIns from '../../libs/axios'

export default {
  namespaced: true,
  state: {
    workplaceSituations: [],
  },
  getters: {
    workplaceSituations: state => state.workplaceSituations,
  },
  mutations: {
    setWorkplaceSituations(state, workplaceSituations) {
      state.workplaceSituations = workplaceSituations
    },
  },
  actions: {
    async fetchWorkplaceSituations({ dispatch, commit }) {
      try {
        const { data: workplaceSituations } = await axiosIns.get('/workplace-situations')
        commit('setWorkplaceSituations', workplaceSituations)
      } catch (e) {
        dispatch('errorHandler', e, { root: true })
        throw e
      }
    },
  },
}
