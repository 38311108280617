import axiosIns from '../../libs/axios'

export default {
  namespaced: true,
  state: {
    jobs: [],
    selectedJob: null,
    pagination: null,
    archivedJobs: [],
    archivedPagination: null,
    showOldJobs: false,
    search: null,
    filterBySpeciality: [],
    filterByCounty: [],
    pageNumber: 1,
  },
  getters: {
    jobs: state => (!state.showOldJobs ? [...state.jobs, ...state.archivedJobs] : state.jobs),
    actualJobsCount: state => state.jobs.length,
    archivedJobs: state => state.archivedJobs,
    selectedJob: state => state.selectedJob,
    search: state => state.search,
    filterBySpeciality: state => state.filterBySpeciality,
    filterByCounty: state => state.filterByCounty,
    pageNumber: state => state.pageNumber,
    query(state) {
      return {
        search: state.search,
        filterBySpeciality: state.filterBySpeciality,
        filterByCounty: state.filterByCounty,
        pageNumber: state.pageNumber,
        showOldJobs: state.showOldJobs,
      }
    },
    pagination: state => {
      if (!state.showOldJobs && state.archivedPagination) {
        if (state.archivedPagination.total === 1 && state.pagination.total === 1) {
          return {
            total: 1,
            per_page: state.pagination.per_page,
          }
        }
        if (state.pagination.total === 0) {
          return {
            total: state.archivedPagination.total,
            per_page: state.pagination.per_page,
          }
        }
        return {
          total: state.archivedPagination.total + state.pagination.total - ((state.archivedPagination.total + state.pagination.total) % 10),
          per_page: state.pagination.per_page,
        }
      }
      return state.pagination
    },
  },
  mutations: {
    setJobs(state, jobs) {
      state.jobs = jobs
    },
    setSelectedJob(state, selectedJob) {
      state.selectedJob = selectedJob
    },
    setSearch(state, search) {
      if (search) state.search = search
      else state.search = null
    },
    setFilterBySpeciality(state, filterBySpeciality) {
      state.filterBySpeciality = filterBySpeciality
    },
    setFilterByCounty(state, filterByCounty) {
      state.filterByCounty = filterByCounty
    },
    setPageNumber(state, pageNumber) {
      state.pageNumber = pageNumber
    },
    setQuery(state, query) {
      state.pageNumber = query.pageNumber
      state.filterByCounty = query.filterByCounty
      state.filterBySpeciality = query.filterBySpeciality
      state.search = query.search
      state.showOldJobs = query.showOldJobs
    },
    clearFilters(state) {
      state.search = null
      state.filterByCounty = []
      state.filterBySpeciality = []
      state.pageNumber = 1
      state.showOldJobs = false
    },
    setArchivedJobs(state, archivedJobs) {
      state.archivedJobs = archivedJobs
    },
    setPagination(state, pagination) {
      state.pagination = pagination
    },
    setArchivedPagination(state, archivedPagination) {
      state.archivedPagination = archivedPagination
    },
    setShowOldCourse: (state, value) => {
      if (value && state.pagination) {
        state.pageNumber = state.pagination.total_pages
      }
      state.showOldJobs = value
    },
  },
  actions: {
    async fetchJobs({ dispatch, commit, state }) {
      commit('setJobs', [])
      const query = {
        search: state.search,
        filterBySpeciality: state.filterBySpeciality,
        filterByCounty: state.filterByCounty,
      }
      try {
        const { data: jobs } = await axiosIns.get(`/jobs?page%5Bnumber%5D=${state.pageNumber}`, {
          params: query,
        })
        commit('setJobs', jobs.data)
        commit('setPagination', jobs.meta.pagination)
        dispatch('fetchArchivedJobs')
      } catch (e) {
        dispatch('errorHandler', e, { root: true })
        throw e
      }
    },
    async fetchArchivedJobs({ dispatch, commit, state }) {
      const query = {
        search: state.search,
        filterBySpeciality: state.filterBySpeciality,
        filterByCounty: state.filterByCounty,
      }
      try {
        const { data: jobs } = await axiosIns.get(`/jobs/archived?page%5Bnumber%5D=${state.pageNumber - state.pagination.total_pages + 1}`, {
          params: query,
        })
        if (!state.showOldJobs && (state.pageNumber - state.pagination.total_pages + 1) > 0) {
          commit('setArchivedJobs', jobs.data)
          commit('setArchivedPagination', jobs.meta.pagination)
        } else if (state.showOldJobs) {
          commit('setArchivedJobs', [])
          commit('setArchivedPagination', {
            total: 0,
            per_page: 0,
          })
        } else {
          commit('setArchivedJobs', [])
          commit('setArchivedPagination', jobs.meta.pagination)
        }
      } catch (e) {
        dispatch('errorHandler', e, { root: true })
        throw e
      }
    },
    async fetchJobById({ dispatch, commit }, id) {
      try {
        const { data: job } = await axiosIns.get(`/jobs/${id}`)
        commit('setSelectedJob', job)
      } catch (e) {
        dispatch('errorHandler', e, { root: true })
        throw e
      }
    },
    async createJob({ dispatch }, job) {
      try {
        return await axiosIns.post('/my/jobs', job)
      } catch (e) {
        dispatch('errorHandler', e, { root: true })
        throw e
      }
    },
    async markAsViewed({ dispatch }, id) {
      try {
        await axiosIns.get(`/jobs/${id}/mark-as-viewed`)
      } catch (e) {
        dispatch('errorHandler', e, { root: true })
        throw e
      }
    },
  },
}
