/* eslint-disable import/no-cycle */
import axiosIns from '../../libs/axios'

export default {
  namespaced: true,
  state: {
    products: null,
    selectedProduct: null,
    search: null,
    pageNumber: 1,
  },
  getters: {
    products: state => state.products,
    selectedProduct: state => state.selectedProduct,
    search: state => state.search,
    pageNumber: state => state.pageNumber,
    query(state) {
      return {
        search: state.search,
        pageNumber: state.pageNumber,
      }
    },
  },
  mutations: {
    setProducts(state, products) {
      state.products = products
    },
    setSelectedProduct(state, selectedProduct) {
      state.selectedProduct = selectedProduct
    },
    setSearch: (state, search) => {
      state.search = search
    },
    setPageNumber: (state, pageNumber) => {
      state.pageNumber = pageNumber
    },
    setQuery(state, query) {
      state.pageNumber = query.pageNumber
      state.search = query.search
    },
    clearFilters(state) {
      state.search = null
      state.pageNumber = 1
    },
  },
  actions: {
    async fetchProducts({ dispatch, commit, state }) {
      const query = {
        search: state.search,
      }
      try {
        const { data: products } = await axiosIns.get(`/my/products?page%5Bnumber%5D=${state.pageNumber}`, {
          params: query,
        })
        commit('setProducts', products)
      } catch (e) {
        dispatch('errorHandler', e, { root: true })
        throw e
      }
    },
    async fetchProductById({ dispatch, commit }, id) {
      try {
        const { data: product } = await axiosIns.get(`/my/products/${id}`)
        commit('setSelectedProduct', product)
      } catch (e) {
        dispatch('errorHandler', e, { root: true })
        throw e
      }
    },
    async createProduct({ dispatch }, product) {
      try {
        return await axiosIns({
          method: 'post',
          url: '/my/products',
          data: product,
          headers: { 'Content-Type': 'multipart/form-data' },
        })
      } catch (e) {
        dispatch('errorHandler', e, { root: true })
        throw e
      }
    },
    async updateProduct({ dispatch }, payload) {
      try {
        return await axiosIns({
          method: 'post',
          url: `/my/products/${payload.id}`,
          data: payload.product,
          headers: { 'Content-Type': 'multipart/form-data' },
        })
      } catch (e) {
        dispatch('errorHandler', e, { root: true })
        throw e
      }
    },
    async changeStockStatus({ dispatch }, payload) {
      try {
        return await axiosIns.post(`/my/products/${payload.id}/${payload.status ? 'mark-as-not-available' : 'mark-as-in-stock'}`)
      } catch (e) {
        dispatch('errorHandler', e, { root: true })
        throw e
      }
    },
    async deleteProductImage({ dispatch }, payload) {
      try {
        return await axiosIns.delete(`/my/products/${payload.id}/image/${payload.imageId}`)
      } catch (e) {
        dispatch('errorHandler', e, { root: true })
        throw e
      }
    },
  },
}
