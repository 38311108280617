export default {
  namespaced: true,
  getters: {
    locale: () => localStorage.getItem('locale'),
  },
  mutations: {
    setLocale(state, locale) {
      state.locale = locale
      localStorage.setItem('locale', locale)
    },
  },
  actions: {
    setLocale({ commit }, locale) {
      commit('setLocale', locale)
    },
  },
}
