import axiosIns from '../../libs/axios'

export default {
  namespaced: true,
  state: {
    prevImageVideoSrc: null,
    prevImageVideoLink: '/',
  },
  getters: {
    prevImageVideoSrc: state => state.prevImageVideoSrc,
    prevImageVideoLink: state => state.prevImageVideoLink,
  },
  mutations: {
    setPrevImageVideoSrc(state, prevImageVideoSrc) {
      state.prevImageVideoSrc = prevImageVideoSrc
    },
    setPrevImageVideoLink(state, prevImageVideoLink) {
      state.prevImageVideoLink = prevImageVideoLink
    },
  },
  actions: {
    async fetchFrontendSettings({ dispatch, commit }) {
      try {
        const {
          data: {
            prev_image_video_src,
            prev_image_video_link,
          },
        } = await axiosIns.get('/frontend-settings')

        commit('setPrevImageVideoSrc', prev_image_video_src)
        commit('setPrevImageVideoLink', prev_image_video_link)
      } catch (e) {
        dispatch('errorHandler', e, { root: true })
        throw e
      }
    },
  },
}
