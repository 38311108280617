import Vue from 'vue'
import Vuex from 'vuex'
import axiosIns from '@/libs/axios'

// Modules
import app from './app'
import auth from './auth'
import audio from './audio'
import appConfig from './app-config'
import categories from './categories'
import clinics from './clinics'
import course from './course'
import customWorkplaces from './custom-workplaces'
import departments from './departments'
import myCourse from './my-course'
import myJobs from './my-jobs'
import myProducts from './my-products'
import filterData from './filter_data'
import hospitals from './hospitals'
import locale from './locale'
import marketplace from './marketplace'
import jobs from './jobs'
import jobCategories from './job-categories'
import specialties from './specialties'
import settings from './settings'
import universities from './universities'
import verticalMenu from './vertical-menu'
import videos from './videos'
import workplaceSituations from './workplace-situations'
import workplaces from './workplaces'
import academicLevels from './academic-levels'
import profileCompany from './profile-company'
import notifications from './notifications'
import posts from './posts'
import postComments from './post-comments'
import publicProfile from './public-profile'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    academicLevels,
    auth,
    audio,
    appConfig,
    categories,
    clinics,
    course,
    customWorkplaces,
    departments,
    myCourse,
    myJobs,
    myProducts,
    filterData,
    hospitals,
    locale,
    marketplace,
    jobs,
    jobCategories,
    specialties,
    settings,
    universities,
    verticalMenu,
    videos,
    workplaceSituations,
    workplaces,
    profileCompany,
    notifications,
    posts,
    postComments,
    publicProfile,
  },
  state: ({
    error: { type: '', value: '' },
    formErrors: [],
  }),
  mutations: {
    setError(state, { error, type }) {
      state.error.value = error
      state.error.type = type || 'danger'
    },
    setFormErrors(state, errors) {
      state.formErrors = errors
    },
    clearErrors(state) {
      state.error = { type: '', value: '' }
      state.formErrors = []
    },
  },

  getters: {
    error: state => state.error,
    formErrors: state => state.formErrors,
  },

  actions: {
    errorHandler({ commit }, e) {
      if (e.response !== undefined && e.response.data !== '') {
        if (e.response.status === 503) {
          EventBus.$emit('showOnMaintenance')
        }
        if (
          e.response.status === 422
          && e.response.data.errors
          && typeof e.response.data.errors === 'object'
        ) {
          commit('setFormErrors', Object.entries(e.response.data.errors))
        } else if (e.response.data.message) {
          commit('setError', { error: e.response.data.message })
        }
      }
    },
    formErrorResponseResolver(_, e) {
      return e.response.data.errors
    },
    async uploadImage({ dispatch }, file) {
      try {
        const { data: { data: image } } = await axiosIns({
          method: 'post',
          url: '/upload-image',
          data: file,
          headers: { 'Content-Type': 'multipart/form-data' },
        })
        return image
      } catch (e) {
        dispatch('errorHandler', e, { root: true })
        throw e
      }
    },
  },

  strict: process.env.DEV,
})
