import axiosIns from '../../libs/axios'

export default {
  namespaced: true,
  state: {
    workplaces: [],
  },
  getters: {
    workplaces: state => state.workplaces,
  },
  mutations: {
    setWorkplaces(state, workplaces) {
      state.workplaces = workplaces
    },
  },
  actions: {
    async fetchWorkplaces({ dispatch, commit }) {
      try {
        const { data: { data: workplaces } } = await axiosIns.get('/partner-companies')
        commit('setWorkplaces', workplaces)
      } catch (e) {
        dispatch('errorHandler', e, { root: true })
        throw e
      }
    },
    async createWorkplace({ dispatch }, workplace) {
      try {
        return await axiosIns({
          method: 'post',
          url: '/partner-companies',
          data: workplace,
          headers: { 'Content-Type': 'multipart/form-data' },
        })
      } catch (e) {
        dispatch('errorHandler', e, { root: true })
        throw e
      }
    },
  },
}
