function showNoHPRConfirmedModal() {
  EventBus.$emit('showNoHPRConfirmedMessage-global')
}

export default function noHPRConfirmed({ next, store }) {
  if (store.getters['auth/user'].status_label !== 'In review' && store.getters['auth/user'].user_info.approved_hrp !== 2) {
    // if page loaded first time
    if (document.readyState === 'loading') {
      window.addEventListener('load', () => {
        showNoHPRConfirmedModal()
      })
    }
    showNoHPRConfirmedModal()
    return next({ name: 'home' })
  }

  return next()
}
