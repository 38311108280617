import axiosIns from '../../libs/axios'

export default {
  namespaced: true,
  state: {
    workplaces: [],
  },
  getters: {
    workplaces: state => state.workplaces,
  },
  mutations: {
    setWorkplaces(state, workplaces) {
      state.workplaces = workplaces
    },
  },
  actions: {
    async fetchWorkplaces({ dispatch, commit }) {
      try {
        const { data: { data: workplaces } } = await axiosIns.get('/custom-workplaces')
        commit('setWorkplaces', workplaces)
      } catch (e) {
        dispatch('errorHandler', e, { root: true })
        throw e
      }
    },
    async createWorkplace({ dispatch }, workplace) {
      try {
        const { data: newWorkplace } = await axiosIns.post('/custom-workplaces', workplace)
        return newWorkplace
      } catch (e) {
        dispatch('errorHandler', e, { root: true })
        throw e
      }
    },
  },
}
