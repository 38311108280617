import axiosIns from '../../libs/axios'

export default {
  namespaced: true,
  state: {
    specialties: [],
    universitySpecialties: [],
  },
  getters: {
    specialties: state => state.specialties,
    universitySpecialties: state => state.universitySpecialties,
    specialty: state => id => state.specialties.find(el => el.id === id),
  },
  mutations: {
    setSpecialties(state, specialties) {
      state.specialties = specialties
    },
    setUniversitySpecialties(state, universitySpecialties) {
      state.universitySpecialties = universitySpecialties
    },
  },
  actions: {
    async fetchSpecialties({ dispatch, commit }) {
      try {
        const { data: { data: specialties } } = await axiosIns.get('/specialties')
        commit('setSpecialties', specialties)
      } catch (e) {
        dispatch('errorHandler', e, { root: true })
        throw e
      }
    },
    async fetchUniversitySpecialties({ dispatch, commit }) {
      try {
        const { data: { data: universitySpecialties } } = await axiosIns.get('/university-specialties')
        commit('setUniversitySpecialties', universitySpecialties)
      } catch (e) {
        dispatch('errorHandler', e, { root: true })
        throw e
      }
    },
  },
}
