import Cookies from 'js-cookie'
import { env } from '@/config/config'

export default function (path, body = {}) {
  fetch(env.baseUrl + path, {
    keepalive: true,
    method: 'POST',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${Cookies.get('token')}`,
      'Content-Type': 'application/json',
      'X-localization': localStorage.getItem('locale'),
    },
    body: JSON.stringify(body),
  })
}
