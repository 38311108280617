function showRegistrationRequiredModal() {
  EventBus.$emit('showRegistrationRequiredModal')
}

export default function auth({ next, store }) {
  if (!store.getters['auth/isAuthenticated']) {
    // if page loaded first time
    if (document.readyState === 'loading') {
      window.addEventListener('load', () => {
        showRegistrationRequiredModal()
      })
    }
    showRegistrationRequiredModal()
    return next({ name: 'home' })
  }

  return next()
}
