import axiosIns from '../../libs/axios'

export default {
  namespaced: true,
  state: {
    universities: [],
  },
  getters: {
    universities: state => state.universities,
  },
  mutations: {
    setUniversities(state, universities) {
      state.universities = universities
    },
  },
  actions: {
    async fetchUniversities({ dispatch, commit }) {
      try {
        const { data: { data: universities } } = await axiosIns.get('/universities')
        commit('setUniversities', universities)
      } catch (e) {
        dispatch('errorHandler', e, { root: true })
        throw e
      }
    },
  },
}
