import axiosIns from '../../libs/axios'

export default {
  namespaced: true,
  state: {
    clinics: [],
    hospitalClinics: [],
  },
  getters: {
    clinics: state => state.clinics,
    hospitalClinics: state => state.hospitalClinics,
  },
  mutations: {
    setClinics(state, clinics) {
      state.clinics = clinics
    },
    setHospitalClinics(state, hospitalClinics) {
      state.hospitalClinics = hospitalClinics
    },
    clearClinics(state) {
      state.clinics = []
    },
    clearHospitalClinics(state) {
      state.hospitalClinics = []
    },
  },
  actions: {
    async fetchClinics({ dispatch, commit }) {
      try {
        const { data: clinics } = await axiosIns.get('/clinics')
        commit('setClinics', clinics)
      } catch (e) {
        dispatch('errorHandler', e, { root: true })
        throw e
      }
    },
    async fetchClinicsByCityId({ dispatch, commit }, id) {
      try {
        const { data: clinics } = await axiosIns.get(`/clinics/${id}/city`)
        commit('setClinics', clinics)
      } catch (e) {
        dispatch('errorHandler', e, { root: true })
        throw e
      }
    },
    async fetchHospitalClinicsByHospitalId({ dispatch, commit }, id) {
      try {
        const { data: hospitalClinics } = await axiosIns.get(`/hospital-clinics/${id}/hospital`)
        commit('setHospitalClinics', hospitalClinics)
      } catch (e) {
        dispatch('errorHandler', e, { root: true })
        throw e
      }
    },
    async fetchHospitalClinics({ dispatch, commit }) {
      try {
        const { data: hospitalClinics } = await axiosIns.get('/hospital-clinics')
        commit('setHospitalClinics', hospitalClinics)
      } catch (e) {
        dispatch('errorHandler', e, { root: true })
        throw e
      }
    },

  },
}
