import axiosIns from '../../libs/axios'

export default {
  namespaced: true,
  state: {
    cities: [],
    citiesByCountyId: [],
    countries: [],
    counties: [],
  },
  getters: {
    cities: state => state.cities,
    citiesByCountyId: state => state.citiesByCountyId,
    countries: state => state.countries,
    counties: state => state.counties,
  },
  mutations: {
    setCities(state, cities) {
      state.cities = cities
    },
    setCitiesByCountyId(state, citiesByCountyId) {
      state.citiesByCountyId = citiesByCountyId
    },
    setCountries(state, countries) {
      state.countries = countries
    },
    setCounties(state, counties) {
      state.counties = counties
    },
    clearCities(state) {
      state.citiesByCountyId = []
    },
  },
  actions: {
    async fetchCitiesByCountyId({ dispatch, commit }, id) {
      try {
        const { data: cities } = await axiosIns.get(`/cities/${id}/county`)
        commit('setCitiesByCountyId', cities)
      } catch (e) {
        dispatch('errorHandler', e, { root: true })
        throw e
      }
    },
    async fetchCities({ dispatch, commit }) {
      try {
        const { data: { data: cities } } = await axiosIns.get('/cities')
        commit('setCities', cities)
      } catch (e) {
        dispatch('errorHandler', e, { root: true })
        throw e
      }
    },
    async fetchCountries({ dispatch, commit }) {
      try {
        const { data: { data: countries } } = await axiosIns.get('/countries')
        commit('setCountries', countries)
      } catch (e) {
        dispatch('errorHandler', e, { root: true })
        throw e
      }
    },
    async fetchCounties({ dispatch, commit }) {
      try {
        const { data: { data: counties } } = await axiosIns.get('/counties')
        commit('setCounties', counties)
      } catch (e) {
        dispatch('errorHandler', e, { root: true })
        throw e
      }
    },
  },
}
