/* eslint-disable import/no-cycle */
import axiosIns from '../../libs/axios'

export default {
  namespaced: true,
  state: {
    postsComments: [],
  },
  getters: {
    postsComments: state => id => (state.postsComments[id] || []),
  },
  mutations: {
    setPostComments(state, payload) {
      const obj = { ...state.postsComments }
      obj[payload.id] = payload.data
      state.postsComments = { ...obj }
    },
  },
  actions: {
    async fetchPostCommentsById({ dispatch, commit }, id) {
      try {
        const { data: { data } } = await axiosIns.get(`/post/${id}/comments`)
        if (data) {
          commit('setPostComments', {
            id,
            data,
          })
        }
      } catch (e) {
        dispatch('errorHandler', e, { root: true })
        throw e
      }
    },
    async addCommentToPostById({ dispatch }, payload) {
      try {
        await axiosIns.post(`/post/${payload.id}/comments`, {
          text: payload.text,
        })
        dispatch('fetchPostCommentsById', payload.id)
      } catch (e) {
        dispatch('errorHandler', e, { root: true })
        throw e
      }
    },
  },
}
