import Vue from 'vue'
import VueRouter from 'vue-router'

import authRoutes from '@/router/auth'
import pagesRoutes from '@/router/pages'
import errorsRoutes from '@/router/errors'

import store from '../store'
import middlewarePipeline from './middlewarePipeline'

Vue.use(VueRouter)

// init authorization state
store.dispatch('auth/initState')

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    ...authRoutes,
    ...pagesRoutes,
    ...errorsRoutes,
  ],
})

router.beforeEach((to, from, next) => {
  if (!to.meta.middleware) {
    return next()
  }
  const { middleware } = to.meta

  const context = {
    to,
    from,
    next,
    store,
  }
  return middleware[0]({
    ...context,
    next: middlewarePipeline(context, middleware, 1),
  })
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
