import axiosIns from '../../libs/axios'

export default {
  namespaced: true,
  state: {
    hospitals: [],
  },
  getters: {
    hospitals: state => state.hospitals,
  },
  mutations: {
    setHospitals(state, hospitals) {
      state.hospitals = hospitals
    },
  },
  actions: {
    async fetchHospitals({ dispatch, commit }) {
      try {
        const { data: { data: hospitals } } = await axiosIns.get('/hospitals')
        commit('setHospitals', hospitals)
      } catch (e) {
        dispatch('errorHandler', e, { root: true })
        throw e
      }
    },
  },
}
