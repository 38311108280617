import Vue from 'vue'

// axios
import axios from 'axios'
import Cookies from 'js-cookie'
import { env } from '@/config/config'

class AxiosResolver {
  axiosIns = null

  jwtConfig = {
    baseURL: env.baseUrl,
    // timeout: 1000,
    // headers: {'X-Custom-Header': 'foobar'}
  }

  isAlreadyFetchingAccessToken = false

  subscribers = []

  constructor(axiosIns) {
    this.axiosIns = axiosIns.create(this.jwtConfig)

    this.axiosIns.interceptors.request.use(request => {
      this.showLoader()

      const locale = localStorage.getItem('locale')
      if (locale) {
        /* eslint-disable no-param-reassign */
        request.headers.common['X-localization'] = locale
      }

      if (Cookies.get('token') && !request.headers.common.Authorization) {
        /* eslint-disable no-param-reassign */
        request.headers.common.Authorization = `Bearer ${Cookies.get('token')}`
      }

      return request
    }, error => {
      this.hideLoader()
      return Promise.reject(error)
    })

    this.axiosIns.interceptors.response.use(
      successRes => {
        this.hideLoader()
        return successRes
      },
      error => {
        const { config, response } = error
        const originalRequest = config

        this.hideLoader()

        if (
          response !== undefined
          && response.data !== undefined
          && response.data.status_code === 401
        ) {
          if (!this.isAlreadyFetchingAccessToken) {
            this.isAlreadyFetchingAccessToken = true

            this.refreshToken().then(r => {
              this.isAlreadyFetchingAccessToken = false
              this.setRefreshToken(r.data.access_token)
              this.onAccessTokenFetched(r.data.access_token)
            }).catch(() => {
              this.logout()
            })
          }
          return new Promise(resolve => {
            this.addSubscriber(accessToken => {
              originalRequest.headers.Authorization = `Bearer ${accessToken}`
              resolve(this.axiosIns(originalRequest))
            })
          })
        }
        return Promise.reject(error)
      },
    )
  }

  getInstance() {
    return this.axiosIns
  }

  onAccessTokenFetched(accessToken) {
    this.subscribers = this.subscribers.filter(callback => callback(accessToken))
  }

  addSubscriber(callback) {
    this.subscribers.push(callback)
  }

  // eslint-disable-next-line class-methods-use-this
  setRefreshToken(value) {
    EventBus.$emit('updateToken', value)
  }

  refreshToken() {
    return this.axiosIns.post('/user/access-token/refresh')
  }

  logout() {
    this.isAlreadyFetchingAccessToken = false
    EventBus.$emit('logout')
  }

  // eslint-disable-next-line class-methods-use-this
  showLoader() {
    EventBus.$emit('showLoader')
  }

  // eslint-disable-next-line class-methods-use-this
  hideLoader() {
    EventBus.$emit('hideLoader')
  }
}

const axiosIns = new AxiosResolver(axios).getInstance()

Vue.prototype.$http = axiosIns

export default axiosIns
